
import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge"
import { Link } from "gatsby"
import { getEntityUrl } from "../../util";

import Menu from "../imagelist/menu";
import EntityKeypad from "../../components/Keypad";

import Wrapper from "../../components/Wrapper";
import ComponentsWrapper from "../../components/ComponentsWrapper";

import List from "./list";

import "./reni-settings.css";
import "./richtext.css";
import "./collapsible.css"

const PREFIX = 'EntityPage';

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        padding: '0',
        textDecoration: 'none',
        fontFamily: 'opensans, Verdana, Helvetica, Arial, sans-serif;',
    },

}));

const HeadlineDiv = styled('div')(({ theme }) => ({
    padding: '20px 8px',
    fontSize: '22px',
    lineHeight: '15px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
}));

const BildbuttonLink = styled(Link)(() => ({
    display: 'block',
    position: 'absolute',
    fontFamily: 'opensans, Verdana, Helvetica, Arial, sans-serif',
    fontStretch: 'normal',
    font: '15px opensans, Verdana, Helvetica, Arial, sans-serif',
    /* font-family: 'openlight', */
    bottom: '12px',
    left: '10px',
    whiteSpace: 'nowrap',
    padding: '8px 15px',
    background: 'hsla(0, 0%, 0%, 0.27)',
    fontSize: '16px',
    color: '#ffffff!important',
    border: '1px solid #FFF',
    letterSpacing: '0.06em',
    textDecoration: 'none!important',
    textTransform: 'uppercase',
    fontWeight: 700,
}));

const EntityPage = (props) => {
    const { entity, config, selectedModule, pageContext } = props;

    const { defaultLocale } = pageContext;

    const image = entity.largeImage ? getImage(entity.largeImage.localFile) : null;
    const headline = (entity.shortDesc || '').replace(/(?:\r\n|\r|\n)/g, '<br />');

    const [keypadShown, setKeypadShown] = useState(config?.showKeypadFirst || false);
    const keypadToggle = () => {
        setKeypadShown(!keypadShown);
    };

    return <Root className="renoir" dataId={entity.identifier}>
        <Wrapper {...props} menu={Menu} showLanguageSelector={false} title={entity.title} keypadSwitch={config?.showKeypad || false} keypadShown={keypadShown} onKeypad={keypadToggle}>
            {keypadShown ?
                <EntityKeypad {...{ entity }} {...props} /> :

                <div className={classes.root}>
                    {image !== null &&
                        <div style={{ width: '100%', height: '296px'}}>
                            <BgImage image={image} alt={headline} layout="fullWidth" style={{ width: '100%', height: '100%' }}>
                                {config.headerLink !== undefined && config.headerLink !== null &&
                                    <BildbuttonLink className={` ${classes.bildbutton}`}
                                        to={getEntityUrl({ entity: config.headerLink, defaultLocale })}>{config.headerLink.title}</BildbuttonLink>
                                }
                            </BgImage>
                        </div>
                    }
                    {headline?.length > 0 && <HeadlineDiv>{headline}</HeadlineDiv>}

                    <ComponentsWrapper {...props} />

                    <List {...props} />
                </div>
            }
        </Wrapper>
    </Root>;
};

export default EntityPage;
